import React, { useEffect, useState } from 'react'
import logo from '../../../assets/images/logo.png'
import logoVertical from '../../../assets/images/logo_vertical.png'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import MenuIcon from '@mui/icons-material/Menu'
import {
  BarChartOutlined,
  CasinoOutlined,
  ContactsOutlined,
  EmojiEventsOutlined,
  Facebook,
  GavelOutlined,
  Instagram,
  LogoutOutlined,
  PersonOutlined, ShoppingBasketSharp,
  SupervisorAccountOutlined
} from '@mui/icons-material'
import UserHelper from '../../../helpers/UserHelper/UserHelper'
import { Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import ChangeLang from '../../organisms/ChangeLang/ChangeLang'
import { useHistory } from 'react-router'

const Navigation = ({ defaultLang }) => {
  const t = LocalStorageHelper.get('translation')
  const [open, setOpen] = useState(false)
  const path = window?.location?.pathname
  const history = useHistory()
  const style = {
    root: {
      paddingTop: 15,
      height: 'calc(100vh - 15px)',
      width: 70,
      overflow: 'hidden',
      position: 'fixed',
      display: BreakpointsHelper.isMobile() ? 'none' : 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      boxShadow: 'rgb(0 0 0 / 15%) 2px 2px 5px 2px',
    },
    rootMobileOpen: {
      display: 'flex',
      cursor: 'pointer',
      flexDirection: 'column',
      position: 'fixed',
      zIndex: 998,
      top: 0,
      width: '100%',
      height: '100vh',
      alignItems: 'start',
      background: 'white',
      justifyContent: 'start',
    },
    rootMobileClose: {
      display: 'none',
    },
    name: {
      display: 'flex',
      flexDirection: 'column',
      width: 235,
      padding: '15px',
    },
    social: {
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      padding: BreakpointsHelper.isMobile() ? '0 15px' : '0',
    },
    iconInsta: {
      color: '#e83464',
    },
    iconFace: {
      color: '#026ae3',
    },
    img: {
      marginLeft: '5px',
      height: '60px',
      padding: open && '15px 0',
    },
    nav: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: BreakpointsHelper.isMobile() ? 'flex-start' : 'center',
      fontSize: BreakpointsHelper.isMobile() ? '17px' : '21px',
      padding: BreakpointsHelper.isMobile() ? '10px 15px' : '10px 15px',
      fontWeight: 700,
      color: '#121a44',
      lineHeight: '26px',
      textTransform: 'uppercase',
    },
    navMobile: {
      marginLeft: '10px',
      fontSize: '20px',
    },
    navigationList: {
      maxHeight: 'calc(100vh - 200px)',
      width: '100%',
      overflow: 'auto',
      marginTop: BreakpointsHelper.isMobile() && '50px',
    },
    burger: {
      position: 'fixed',
      top: '0',
      background: 'white',
      zIndex: 999,
      width: 'calc(100% - 20px)',
      height: '50px',
      boxShadow: 'rgb(0 0 0 / 15%) 2px 2px 5px 2px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 15px 0 5px',
    },
    menu: {
      width: 40,
      height: 40,
      color: '#121a44',
      cursor: 'pointer',
    },
  }
  const handleOpen = (url, target = '_self', outSide = null) => {
    if (BreakpointsHelper.isMobile()) setOpen(false)
    if (outSide) window.open(url, target)
    else history?.push(url)
  }
  useEffect(() => {
    if (open) document.body.classList.add('open')
    else if (document.body.classList.contains('open')) document.body.classList.remove('open')
  }, [open])
  return (
		<>
			<div className="Navigation" style={BreakpointsHelper.isMobile() ? open ? style.rootMobileOpen : style.rootMobileClose : style.root}>
        <Box sx={style.navigationList}>
          { !BreakpointsHelper.isMobile() && <img onClick={() => history.push('/events')} src={BreakpointsHelper.isMobile() ? logoVertical : logo} alt="logo" style={style.img} /> }
          <Tooltip title={t.globals.nav.langChange} placement="right">
            <div>
              <ChangeLang defaultLang={defaultLang} />
            </div>
          </Tooltip>
          {
            LocalStorageHelper.get('user')?.role === 'superadmin' && (
              <Tooltip title={t.globals.nav.adminPage} placement="right">
                <div className={`menuNav ${path === '/admin' && 'active'}`} onClick={() => handleOpen('/shopAdmin')} style={style.nav}>
                  <ShoppingBasketSharp />
                  { BreakpointsHelper.isMobile() && <span style={style.navMobile}>Shop Admin</span> }
                </div>
              </Tooltip>
            )
          }
          {
            (LocalStorageHelper.get('user')?.role === 'admin' || LocalStorageHelper.get('user')?.role === 'volunteer' || LocalStorageHelper.get('user')?.role === 'superadmin') && (
              <Tooltip title={t.globals.nav.adminPage} placement="right">
                <div className={`menuNav ${path === '/admin' && 'active'}`} onClick={() => handleOpen('/admin')} style={style.nav}>
                  <SupervisorAccountOutlined />
                  { BreakpointsHelper.isMobile() && <span style={style.navMobile}>{t.globals.nav.adminPage}</span> }
                </div>
              </Tooltip>
            )
          }
          <Tooltip title={t.globals.nav.eventsPage} placement="right">
            <div className={`menuNav ${path === '/events' && 'active'}`} onClick={() => handleOpen('/events')} style={style.nav}>
              <EmojiEventsOutlined />
              { BreakpointsHelper.isMobile() && <span style={style.navMobile}>{t.globals.nav.eventsPage}</span> }
            </div>
          </Tooltip>
          <Tooltip title={t.globals.nav.profilePage} placement="right">
            <div className={`menuNav ${(path === '/profile' || path.search('previewEvent') > 0) && 'active'}`} onClick={() => handleOpen('/profile')} style={style.nav}>
              <PersonOutlined/>
              { BreakpointsHelper.isMobile() && <span style={style.navMobile}>{t.globals.nav.profilePage}</span> }
            </div>
          </Tooltip>
          <Tooltip title={t.globals.nav.gamesPage} placement="right">
            <div className={`menuNav ${path === '/games' && 'active'}`} onClick={() => handleOpen('/games')} style={style.nav}>
              <CasinoOutlined />
              { BreakpointsHelper.isMobile() && <span style={style.navMobile}>{t.globals.nav.gamesPage}</span> }
            </div>
          </Tooltip>
          <Tooltip title={t.globals.nav.rulesPage} placement="right">
            <div className={`menuNav ${path === '/rule' && 'active'}`} onClick={() => handleOpen('/rule')} style={style.nav}>
              <GavelOutlined />
              { BreakpointsHelper.isMobile() && <span style={style.navMobile}>{t.globals.nav.rulesPage}</span> }
            </div>
          </Tooltip>
          <Tooltip title={t.globals.nav.statisticPage} placement="right">
            <div className={`menuNav ${path === '/statistic' && 'active'}`} onClick={() => handleOpen('/statistic')} style={style.nav}>
              <BarChartOutlined />
              { BreakpointsHelper.isMobile() && <span style={style.navMobile}>{t.globals.nav.statisticPage}</span> }
            </div>
          </Tooltip>
          <Tooltip title={t.globals.nav.contactPage} placement="right">
            <div className={`menuNav ${path === '/contact' && 'active'}`} onClick={() => handleOpen('/contact')} style={style.nav}>
              <ContactsOutlined />
              { BreakpointsHelper.isMobile() && <span style={style.navMobile}>{t.globals.nav.contactPage}</span> }
            </div>
          </Tooltip>
          <Tooltip title={t.globals.nav.logout} placement="right">
            <div className="menuNav" onClick={() => UserHelper.logout()} style={style.nav}>
              <LogoutOutlined />
              { BreakpointsHelper.isMobile() && <span style={style.navMobile}>{t.globals.nav.logout}</span> }
            </div>
          </Tooltip>
        </Box>
        <Box sx={style.navigationList}>
          <Tooltip title={t.globals.nav.facebook} placement="right">
            <div className="menuNav" onClick={() => handleOpen('https://www.facebook.com/planszowkiKoszalin', '_blank', true)} style={style.nav}>
              <Facebook style={style.iconFace} />
              { BreakpointsHelper.isMobile() && <span style={style.navMobile}>{t.globals.nav.facebook}</span> }
            </div>
          </Tooltip>
          <Tooltip title={t.globals.nav.instagram} placement="right">
            <div className="menuNav" onClick={() => handleOpen('https://www.instagram.com/planszowkikoszalin', '_blank', true)} style={style.nav}>
              <Instagram style={style.iconInsta} />
              { BreakpointsHelper.isMobile() && <span style={style.navMobile}>{t.globals.nav.instagram}</span> }
            </div>
          </Tooltip>
        </Box>
			</div>
			{
				BreakpointsHelper.isMobile() && (
					<div style={style.burger} onClick={() => setOpen(!open)}>
            <img src={BreakpointsHelper.isMobile() ? logoVertical : logo} alt="logo" style={style.img} />
            <MenuIcon style={style.menu}/>
					</div>
				)
			}
		</>
  )
}

export default Navigation
