import Section from '../../components/atoms/Section/Section'
import Row from '../../components/atoms/Row/Row'
import website1 from '../../assets/images/website/website1.jpg'
import website2 from '../../assets/images/website/website2.jpg'
import website3 from '../../assets/images/website/website3.jpg'
import website4 from '../../assets/images/website/website4.jpg'
import yt from '../../assets/images/yt.png'
import googleplay from '../../assets/images/website/googleplay.png'
import Button from '../../components/atoms/Forms/Button/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import regulamin from '../../assets/pdf/regulamin.pdf'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import GroupAccordion from '../../components/molecules/GroupAccordion/GroupAccordion'
import { Helmet } from 'react-helmet'
import Slider from '../../components/molecules/Slider/Slider'
import Partners from '../../components/molecules/Partners/Partners'
import Contacts from '../../components/organisms/Contacts/Contacts'
import Calendar from '../../components/organisms/Calendar/Calendar'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import { Grid } from '@mui/material'
import oswiadczenie from '../../assets/pdf/oświadczenie.pdf'
import { updateOrCreateDocument } from '../../firebase'
import { useSnackbar } from 'notistack'
import {
	CasinoOutlined,
	CastleOutlined, EmojiPeopleOutlined,
	LocalLibraryOutlined, RedeemOutlined,
	SecurityOutlined,
	TerrainOutlined, TourOutlined
} from '@mui/icons-material'

const HomePage = (props) => {
	const t = LocalStorageHelper.get('translation')
	const { enqueueSnackbar } = useSnackbar()
	const dataToAdd = {
		elements: [
			{
				type: 'text',
				name: 'company',
				label: 'Nazwa firmy',
				validationType: 'string',
			},
			{
				type: 'text',
				name: 'name',
				label: 'Imię i nazwisko osoby do kontaktu',
				validationType: 'string',
			},
			{
				type: 'email',
				label: 'email',
				name: 'email',
				validationType: 'string',
			},
			{
				type: 'number',
				label: 'Telefon',
				name: 'phone',
				validationType: 'number',
			},
			{
				type: 'button',
				value: 'Wyślij'
			}
		]
	}
	const style = {
		title: {
			fontSize: BreakpointsHelper.isMobile() ? '24px' : '42px',
			color: '#121a44',
			fontWeight: '700',
		},
		description: {
			color: '#121a44',
			fontSize: BreakpointsHelper.isMobile() ? '16px' : '22px',
			fontWeight: '400',
			marginBottom: '20px',
			marginTop: '10px',
			maxWidth: '100%',
			textAlign: 'justify',
		},
	}
	const rules = [
		{
			title: t.globals.components.GroupAccordion.events,
			text: t.globals.components.GroupAccordion.eventsData,
		},
		{
			title: t.globals.components.GroupAccordion.price,
			text: t.globals.components.GroupAccordion.priceData,
		},
		{
			title: t.globals.components.GroupAccordion.images,
			text: t.globals.components.GroupAccordion.imagesData,
		},
		{
			title: t.globals.components.GroupAccordion.register,
			text: t.globals.components.GroupAccordion.registerData,
		},
		{
			title: t.globals.components.GroupAccordion.sick,
			text: t.globals.components.GroupAccordion.sickData,
		},
		{
			title: t.globals.components.GroupAccordion.talk,
			text: t.globals.components.GroupAccordion.talkData,
		},
		{
			title: t.globals.components.GroupAccordion.rules,
			text: t.globals.components.GroupAccordion.rulesData,
		},
		{
			title: t.globals.components.GroupAccordion.eat,
			text: t.globals.components.GroupAccordion.eatData,
		},
		{
			title: t.globals.components.GroupAccordion.child,
			text: t.globals.components.GroupAccordion.childData + ' <a href="' + oswiadczenie + '" target="blank" rel="noreferrer">' + t.globals.components.GroupAccordion.childDataLink + '</a>. ' + t.globals.components.GroupAccordion.childData2,
		},
	]
	const elements = [
		{
			title: t?.homePage?.section4.desc5,
			text: t?.homePage?.section4.desc6,
			icon: <EmojiPeopleOutlined />,
		},
		{
			title: t?.homePage?.section4.desc3,
			text: t?.homePage?.section4.desc4,
			icon: <CasinoOutlined />,
		},
		{
			title: t?.homePage?.section4.desc7,
			text: t?.homePage?.section4.desc8,
			icon: <RedeemOutlined />,
		},
		{
			title: t?.homePage?.section4.desc9,
			text: t?.homePage?.section4.desc10,
			icon: <LocalLibraryOutlined />,
		},
		{
			title: t?.homePage?.section4.desc13,
			text: t?.homePage?.section4.desc14,
			icon: <CastleOutlined />,
		},
		{
			title: t?.homePage?.section4.desc11,
			text: t?.homePage?.section4.desc12,
			icon: <TerrainOutlined />,
		},
		{
			title: t?.homePage?.section4.desc15,
			text: t?.homePage?.section4.desc16,
			icon: <SecurityOutlined />,
		},
		{
			title: t?.homePage?.section4.desc17,
			text: t?.homePage?.section4.desc18,
			icon: <TourOutlined />,
		},
	]
	const addPartner = (e) => {
		updateOrCreateDocument('partners', null, e).then(() => {
			enqueueSnackbar('Dodano prośbę o partnerstwo.', { variant: 'success' })
		})
	}
	return (
		<div>
			 <Helmet>
				<meta charSet="utf-8" />
				<title>Planszówki Koszalin | Klub planszówkowy | Koszalin</title>
				<meta name="keywords" content="gry planszowe, Koszalin, planszówki koszalin"/>
				<meta name="description" content="Zarejestruj się i stwórz, dołącz do najbliższego wydarzenia, aby poczuć magię planszówek. Pamiętaj, nie liczy się Twój wiek czy doświadczenie w graniu." />
				<meta name="subject" content="Planszówki Koszalin | Klub planszówkowy | Koszalin"/>
				<meta name="copyright"content="Planszówki Koszalin"/>
				<meta name="language" content="PL"/>
				<meta name="robots" content="index,follow" />
				<meta name="author" content="Jan Szymański, kontakt@planszowki-koszalin.pl"/>
				<meta name="designer" content="Jan Szymański, kontakt@planszowki-koszalin.pl"/>
				<meta name="copyright" content="Jan Szymański, kontakt@planszowki-koszalin.pl"/>
				<meta name="owner" content="Jan Szymański, kontakt@planszowki-koszalin.pl"/>
				<meta name="url" content="https://planszowki-koszalin.pl"/>
				<meta name="identifier-URL" content="https://planszowki-koszalin.pl"/>
				<meta name="category" content="Gry planszowe"/>
				<meta name="distribution" content="Global"/>
				<meta name="rating" content="General"/>
				<meta name="revisit-after" content="7 days"/>
				<meta httpEquiv="Expires" content="0"/>
				<meta httpEquiv="Pragma" content="no-cache"/>
				<meta httpEquiv="Cache-Control" content="no-cache"/>
				<meta name="og:title" content="Planszówki Koszalin | Klub planszówkowy | Koszalin"/>
				<meta name="og:type" content="website"/>
				<meta name="og:url" content="https://planszowki-koszalin.pl"/>
				<meta name="og:image" content="http://localhost:3000/static/media/website1.9f1b6babd1b88a4cad9e.jpg"/>
				<meta name="og:site_name" content="Planszówki Koszalin"/>
				<meta name="og:description" content="Zarejestruj się i stwórz, dołącz do najbliższego wydarzenia, aby poczuć magię planszówek. Pamiętaj, nie liczy się Twój wiek czy doświadczenie w graniu."/>
			  <meta name="fb:page_id" content="planszowkiKoszalin" />
				<meta name="og:email" content="kontakt@planszowki-koszalin.pl"/>
				<meta name="og:phone_number" content="509609439"/>
				<meta name="og:locality" content="Koszalin"/>
				<meta name="og:region" content="Zachodniopomorskie"/>
				<meta name="og:postal-code" content="75-430"/>
				<meta name="og:country-name" content="Polska"/>
				<meta property="og:type" content="website"/>
				<meta name="apple-mobile-web-app-capable" content="yes"/>
				<meta content="yes" name="apple-touch-fullscreen" />
				<meta name="apple-mobile-web-app-status-bar-style" content="black"/>
				<meta name="format-detection" content="telephone=no"/>
        <meta name="google-play-app" content="app-id=com.planszowkikoszalin.boardgames"/>
			 </Helmet>
			<Slider {...props}/>
			<Row
				id="about"
				right={(
					<>
						<Section
							title={t?.homePage?.section1.title}
							object={(
								<>
									<p>{t?.homePage?.section1.desc1}</p>
									<p>{t?.homePage?.section1.desc2}</p>
									<Button sx={{ minWidth: BreakpointsHelper.isMobile() ? '100%' : '100px', marginTop: '20px' }} onClick={() => props?.location?.history?.push('/register')}>
										{t?.homePage?.section1.button}
									</Button>
								</>
							)}
						/>
					</>
				)}
				left={<Section image={website1}/>}
			/>
			<Row
				id="app"
				background
				left={(
					<>
						<Section
							title={t?.homePage?.section2.title}
							object={(
								<>
									<p>{t?.homePage?.section2.desc1}</p>
									<p>{t?.homePage?.section2.desc2}</p>
									<Button sx={{ minWidth: BreakpointsHelper.isMobile() ? '100%' : '100px', marginTop: '20px' }} onClick={() => window.open('https://play.google.com/store/apps/details?id=com.planszowkikoszalin.boardgames', '_blank')}>
										{t?.homePage?.section2.button}
									</Button>
								</>
							)}
						/>
					</>
				)}
				right={<Section image={googleplay}/>}
			/>
			<Partners />
			<Row
				id="where"
				revertMobile
				background
				left={(
					<>
						<Section
							title={t?.homePage?.section3.title}
							object={(
								<>
									<Typography sx={style.description}>
										{t?.homePage?.section3.desc1}
									</Typography>
									<Typography sx={style.description}>
										Cieszymy się, że możemy zapewnić dodatkowe możliwości doświadczenia radości z gier planszowych w Koszalinie! Nasza społeczność oferuje Ci szansę zorganizowania własnego wydarzenia w przytulnym otoczeniu swojego domu, gdzie możesz zaprosić znajomych i bliskich, aby wspólnie cieszyć się planszówkami.
									</Typography>
									<Typography sx={style.description}>
										Dodatkowo, latem organizujemy również ekscytujące gry terenowe na terenie miasta Koszalin i jego okolic. Te rozgrywki umożliwią Ci odkrywanie gier w nowy sposób, czerpiąc przyjemność z zabawy na świeżym powietrzu. Będziesz mógł/mogła wziąć udział w emocjonujących przygodach, rozwiązując zagadki i wyzwania, które wprowadzą Cię w fascynujący świat planszówek.
									</Typography>
									<Typography sx={style.description}>
										Nie czekaj dłużej, dołącz do naszej społeczności i skorzystaj z tych wyjątkowych okazji do cieszenia się planszówkami zarówno w domowym zaciszu, jak i podczas wspaniałych letnich gier terenowych. Zabawa i emocje czekają na Ciebie!
									</Typography>
									</>
							)}
						/>
					</>
				)}
				right={(
					<Section
						image={website3}
					/>
				)}
			/>
			<Box id="games" sx={{ padding: BreakpointsHelper.isMobile() ? '15px' : '50px', display: 'flex', marginBottom: '100px', marginTop: '100px' }}>
				<Grid container spacing={4}>
					<Grid item md={12}>
						<Box sx={{ marginTop: '70px' }}>
							<Typography style={style.title}>{t?.homePage?.section4.title}</Typography>
							<Typography style={style.description}>
								Zapraszamy na niezapomniane wydarzenia związane z grami planszowymi! Nasze spotkania gier planszowych w Koszalinie to idealna okazja, aby zanurzyć się w świat różnorodnych gier, pełnych strategii, tajemnic i emocji. Niezależnie od poziomu doświadczenia, każdy jest mile widziany! Dołącz do naszej entuzjastycznej społeczności, gdzie wspólnie tworzymy wspaniałe wspomnienia, nawiązujemy nowe przyjaźnie i dzielimy się pasją do planszówek. Przyjdź, zagraj i doświadczaj radości towarzyszącej wspólnym rozgrywkom. Czekamy na Ciebie na naszych kolejnych wydarzeniach, które odbędą się w miłej i przyjaznej atmosferze. Przygotuj się na fantastyczną zabawę, która czeka na Ciebie na naszych planszowych spotkaniach!
							</Typography>
							<GroupAccordion elements={elements} />
							<Box sx={{ maxWidth: '1440px', margin: '0 auto', width: '100%' }}>
								<Calendar />
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Box>
			<Row
				id="rules"
				background
				left={(
					<>
						<Section
							title={t?.homePage?.section5.title}
							object={(
								<>
									<Typography sx={style.description}>
										Nasze spotkania gier planszowych w Koszalinie opierają się na spisanych zasadach, które zapewniają uczciwość, jasność i przyjazną atmosferę dla wszystkich uczestników. Dzięki nim każdy może cieszyć się radością ze wspólnej zabawy i pasji do planszówek.
									</Typography>
									<GroupAccordion elements={rules} />
								</>
							)}
						/>
					</>
				)}
				right={(
					<Section
						image={website2}
					/>
				)}
			/>
			<Row
				id="youtube"
				left={(
					<Section
						image={yt}
					/>
				)}
				right={(
					<Section
						title={'Kanał na Youtube'}
						object={(
							<>
								<Typography sx={style.description}>
									Zapraszamy więc na nasz kanał, gdzie czekają na Ciebie konkursy z cennymi nagrodami, interesujące wywiady oraz relacje z pasjonujących wydarzeń planszówkowych. Bądź częścią naszej planszówkowej społeczności i razem z nami odkrywaj fascynujący świat gier planszowych!
								</Typography>
								<Button
									sx={{ maxWidth: '300px', width: 'auto' }}
									variant="contained"
									onClick={() => window.open('https://www.youtube.com/@planszowkikoszalin8836', '_blank')}
								>
									Zobacz nasz kanał
								</Button>
							</>
						)}
					/>
				)}
			/>
			<Row
				background
				id="contacts"
				revertMobile
				left={(
					<Section
						image={website4}
					/>
				)}
				right={(
					<Section
						title={t?.homePage?.section6.title}
						object={(
							<>
								<Typography sx={style.description}>{t?.homePage?.section6.desc1}</Typography>
								<Contacts homePage />
							</>
						)}
					/>
				)}
			/>
			<Box
				sx={{
				  display: 'flex',
				  alignItems: BreakpointsHelper.isMobile() ? 'flex-start' : 'center',
				  justifyContent: 'center',
					flexDirection: BreakpointsHelper.isMobile() && 'column',
				  height: '80px',
				}}
			>
				<Typography
					sx={{
						marginRight: '10px',
						marginLeft: '10px',
						cursor: 'pointer',
					}}
				>
						@ 2022, {t?.homePage?.footer.desc1} <a href="https://your-site.pl" target="_blank" rel="noreferrer">Your site</a>.
				</Typography>
				<Typography
					sx={{
					  marginRight: '10px',
					  marginLeft: '10px',
					  cursor: 'pointer',
					}}
				>
					<a href={regulamin} target="_blank" rel="noreferrer">
						{t?.homePage?.footer.desc2}
					</a>
				</Typography>
				<Typography
					sx={{
					  marginRight: '10px',
					  marginLeft: '10px',
					  cursor: 'pointer',
					}}
				>
					<a href={'/policy'} target="_blank" rel="noreferrer">
						{t?.homePage?.footer.desc3}
					</a>
				</Typography>
			</Box>
		</div>
  )
}

export default HomePage
