import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import Box from '@mui/material/Box'

const PolicyPage = () => {
  const style = {
    root: {
      padding: BreakpointsHelper.isMobile() ? '30px 15px' : '100px 50px',
      display: 'flex',
      flexDirection: 'column'
    }
  }
  return (
    <Box sx={style.root}>
      <h1>Polityka Prywatności</h1>

      <section>
        <h2>§ 1 Postanowienia ogólne</h2>
        <p>Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych osobowych Klientów oraz użytkowników strony internetowej Planszówki Koszalin, dostępnej pod adresem <strong>planszowki-koszalin.pl</strong>.</p>
        <p>Administratorem danych osobowych jest <strong>Your site Jan Szymański</strong>, ul. Holenderska 57/22, 75-430 Koszalin, NIP: 8522589668.</p>
      </section>

      <section>
        <h2>§ 2 Zakres zbieranych danych osobowych</h2>
        <ul>
          <li>Imię i nazwisko</li>
          <li>Adres e-mail</li>
          <li>Numer telefonu</li>
          <li>Adres IP</li>
        </ul>
      </section>

      <section>
        <h2>§ 3 Cele i podstawy prawne przetwarzania danych</h2>
        <ul>
          <li>Organizacja i zarządzanie wydarzeniami planszówkowymi</li>
          <li>Kontakt z uczestnikami</li>
          <li>Zapewnienie bezpieczeństwa wydarzeń</li>
          <li>Działania marketingowe za zgodą użytkownika</li>
        </ul>
      </section>

      <section>
        <h2>§ 4 Udostępnianie danych osobowych</h2>
        <p>Dane mogą być udostępniane podmiotom współpracującym w zakresie organizacji wydarzeń oraz organom publicznym na podstawie obowiązujących przepisów prawa.</p>
      </section>

      <section>
        <h2>§ 5 Okres przechowywania danych</h2>
        <p>Dane osobowe przechowywane są do momentu wycofania zgody przez Klienta lub zgodnie z obowiązkami prawnymi.</p>
      </section>

      <section>
        <h2>§ 6 Prawa Klientów</h2>
        <ul>
          <li>Dostęp do swoich danych</li>
          <li>Sprostowanie danych</li>
          <li>Usunięcie danych</li>
          <li>Ograniczenie przetwarzania danych</li>
          <li>Wniesienie sprzeciwu wobec przetwarzania</li>
        </ul>
      </section>

      <section>
        <h2>§ 7 Pliki cookies i dane techniczne</h2>
        <ul>
          <li>Wykorzystywane do poprawnego działania strony</li>
          <li>Możliwość zarządzania cookies w ustawieniach przeglądarki</li>
        </ul>
      </section>

      <section>
        <h2>§ 8 Zmiany w Polityce Prywatności</h2>
        <p>Administrator zastrzega sobie prawo do zmian, informując Klientów z 14-dniowym wyprzedzeniem.</p>
      </section>
    </Box>
  )
}

export default PolicyPage
