import { BrowserRouter as Router, Switch } from 'react-router-dom'
import MainLayout from './layouts/MainLayout/MainLayout'
import { Redirect, useHistory } from 'react-router'
import './assets/scss/App.scss'
import { PublicRoute } from './router/PublicRoute/PublicRoute'
import LoginPage from './pages/LoginPage/LoginPage'
import SimpleLayout from './layouts/SimpleLayout/SimpleLayout'
import { PrivateRoute } from './router/PrivateRoute/PrivateRoute'
import EventsPage from './pages/EventsPage/EventsPage'
import GamesPage from './pages/GamesPage/GamesPage'
import HomePage from './pages/HomePage/HomePage'
import PreviewLayout from './layouts/PreviewLayout/PreviewLayout'
import RegisterPage from './pages/RegisterPage/RegisterPage'
import ProfilePage from './pages/ProfilePage/ProfilePage'
import PreviewEventPage from './pages/PreviewEventPage/PreviewEventPage'
import RulePage from './pages/RulePage/RulePage'
import ContactPage from './pages/ContactPage/ContactPage'
import AdminPage from './pages/AdminPage/AdminPage'
import RememberPage from './pages/RememberPage/RememberPage'
import LangHelper from './helpers/LangHelper/LangHelper'
import ShowTicketPage from './pages/ShowTicketPage/ShowTicketPage'
import AddGamePage from './pages/AddGamePage/AddGamePage'
import GamePreviewPage from './pages/GamePreviewPage/GamePreviewPage'
import ListYourGamesPage from './pages/ListYourGamesPage/ListYourGamesPage'
import StatisticPage from './pages/StatisticPage/StatisticPage'
import ErrorsPage from './pages/ErrorsPage/ErrorsPage'
import StatisticGamePage from './pages/StatisticGamePage/StatisticGamePage'
import ReactGA from 'react-ga'
import SuperAdminPage from './pages/SuperAdminPage/SuperAdminPage'
import AdminShopPage from './pages/AdminShopPage/AdminShopPage'
import PreviewProductPage from './pages/PreviewProductPage/PreviewProductPage'
import OrderPage from './pages/OrderPage/OrderPage'
import PolicyPage from './pages/PolicyPage/PolicyPage'

const App = () => {
  const history = useHistory()
  LangHelper.setDefaultLanguage(history)
  ReactGA.initialize('G-HPCFQ8RB7R')
  return (
    <Router basename={'/'}>
      <Switch>
        <PublicRoute exact path={'/'} component={() => <Redirect to={'/home'} />} layout={SimpleLayout} />
        <PublicRoute path={'/home'} component={HomePage} layout={PreviewLayout} />
        <PublicRoute path={'/login'} component={LoginPage} layout={SimpleLayout} />
        <PublicRoute path={'/register'} component={RegisterPage} layout={SimpleLayout} />
        <PublicRoute path={'/reset'} component={RememberPage} layout={SimpleLayout} />
        <PublicRoute path={'/order'} component={OrderPage} layout={PreviewLayout} />
        <PublicRoute path={'/policy'} component={PolicyPage} layout={PreviewLayout} />
        <PublicRoute path={'/previewProduct/:id'} component={PreviewProductPage} layout={PreviewLayout} />
        <PrivateRoute path={'/games'} component={GamesPage} layout={MainLayout} />
        <PrivateRoute path={'/shopAdmin'} component={AdminShopPage} layout={MainLayout} />
        <PrivateRoute path={'/statistic'} component={StatisticPage} layout={MainLayout} />
        <PrivateRoute path={'/admin'} component={AdminPage} layout={MainLayout} />
        <PrivateRoute path={'/profile'} component={ProfilePage} layout={MainLayout} />
        <PrivateRoute path={'/yourStatisticGames'} component={StatisticGamePage} layout={MainLayout} />
        <PrivateRoute path={'/addGame'} component={AddGamePage} layout={MainLayout} />
        <PrivateRoute path={'/contact'} component={ContactPage} layout={MainLayout} />
        <PrivateRoute path={'/rule'} component={RulePage} layout={MainLayout} />
        <PrivateRoute path={'/events'} component={EventsPage} layout={MainLayout} />
        <PrivateRoute path={'/showTicket'} component={ShowTicketPage} layout={MainLayout} />
        <PrivateRoute path={'/listYourGames'} component={ListYourGamesPage} layout={MainLayout} />
        <PrivateRoute path={'/previewGame'} component={GamePreviewPage} layout={MainLayout} />
        <PrivateRoute path={'/super'} component={SuperAdminPage} layout={MainLayout} />
        <PrivateRoute path={'/previewEvent/:id'} component={PreviewEventPage} layout={MainLayout} />
        <PublicRoute exact path="*" component={ErrorsPage} layout={SimpleLayout}/>
      </Switch>
    </Router>
  )
}

export default App
